// // @nebular theming framework
// @import 'node_modules/@nebular/theme/styles/_theming.scss';
// // @nebular out of the box themes
// @import 'node_modules/@nebular/theme/styles/themes';
// @import 'node_modules/@nebular/theme/styles/themes/_default.scss';
// @import 'node_modules/@nebular/theme/styles/themes/_dark.scss';
// @import 'node_modules/@nebular/theme/styles/themes/_corporate.scss';
// @import 'node_modules/@nebular/theme/styles/themes/_cosmic.scss';

@forward 'node_modules/@nebular/theme/styles/theming';
@use 'node_modules/@nebular/theme/styles/theming' as *;
@use 'node_modules/@nebular/theme/styles/themes/default';
@use 'node_modules/@nebular/theme/styles/themes/dark';
@use 'node_modules/@nebular/theme/styles/themes/corporate';
@use 'node_modules/@nebular/theme/styles/themes/cosmic';

@font-face {
    font-family: Roboto;
    src: url(/assets/fonts/Roboto/Roboto-Regular.ttf) format("opentype");
}

.nb-theme-default nb-card-body::-webkit-scrollbar {
    width: .5125rem!important;
    height: .5125rem!important;
}

$nb-themes: nb-register-theme(( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #f7f9fc, slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, font-family-primary: unquote('Roboto, sans-serif'), font-family-secondary: unquote('Roboto, sans-serif'), ), default, default);
$nb-themes: nb-register-theme(( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: #252547, slide-out-shadow-color: 2px 0 3px #29157a, slide-out-shadow-color-rtl: -2px 0 3px #29157a, ), cosmic, cosmic);
$nb-themes: nb-register-theme(( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%), slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, ), corporate, corporate);
$nb-themes: nb-register-theme(( layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem, card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem, card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem, select-min-width: 6rem, slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%), slide-out-shadow-color: 0 4px 14px 0 #8f9bb3, slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3, ), dark, dark);
$nb-themes: nb-register-theme(( color-primary-100: #FEEBE0, color-primary-200: #FED2C2, color-primary-300: #FEB3A3, color-primary-400: #FD968C, color-primary-500: #FD6767, color-primary-600: #D94B57, color-primary-700: #B6334A, color-primary-800: #92203E, color-primary-900: #791336, color-primary-transparent-100: rgba(253, 103, 103, 0.08), color-primary-transparent-200: rgba(253, 103, 103, 0.16), color-primary-transparent-300: rgba(253, 103, 103, 0.24), color-primary-transparent-400: rgba(253, 103, 103, 0.32), color-primary-transparent-500: rgba(253, 103, 103, 0.4), color-primary-transparent-600: rgba(253, 103, 103, 0.48), ), custom-dark, dark); // <- theme name and a parent theme
//***************** TEMAS ATERNA
$nb-themes: nb-register-theme((
  font-family-primary: Roboto,
  font-family-secondary :font-family-primary,

  layout-padding-top: 2.25rem, menu-item-icon-margin: 0 0.5rem 0 0, card-height-tiny: 13.5rem,
card-height-small: 21.1875rem, card-height-medium: 28.875rem, card-height-large: 36.5625rem, card-height-giant: 44.25rem,
card-margin-bottom: 1.875rem, card-header-with-select-padding-top: 0.5rem, card-header-with-select-padding-bottom: 0.5rem,
color-aterna-primary: #212492, color-aterna-secondary: #00B092, color-aterna-accent: #CA005D, color-aterna-accent-light: #EEAF30,
 color-aterna-text-user: white, color-primary-100: #FCC9CC, color-primary-200: #F995A5, color-primary-300: #EF5F85,
 color-primary-400: #DF3774, color-primary-500: #CA005D, color-primary-600: #AD0061, color-primary-700: #910060, color-primary-800: #750059,
 color-primary-900: #600053, color-primary-transparent-100: rgba(202, 0, 93, 0.08), color-primary-transparent-200: rgba(202, 0, 93, 0.16),
 color-primary-transparent-300: rgba(202, 0, 93, 0.24), color-primary-transparent-400: rgba(202, 0, 93, 0.32), color-primary-transparent-500: rgba(202, 0, 93, 0.4),
 color-primary-transparent-600: rgba(202, 0, 93, 0.48), color-info-100: #C8FBDF, color-info-200: #94F7CA, color-info-300: #5CE7B5, color-info-400: #33CFA6, color-info-500: #00B092, color-info-600: #00978B, color-info-700: #007C7E, color-info-800: #005A66, color-info-900: #004354, color-info-transparent-100: rgba(0, 176, 146, 0.08), color-info-transparent-200: rgba(0, 176, 146, 0.16), color-info-transparent-300: rgba(0, 176, 146, 0.24), color-info-transparent-400: rgba(0, 176, 146, 0.32), color-info-transparent-500: rgba(0, 176, 146, 0.4), color-info-transparent-600: rgba(0, 176, 146, 0.48), color-basic-100: #ffffff, color-basic-200: #f7f9fc, color-basic-300: #edf1f7, color-basic-400: #e4e9f2, color-basic-500: #c5cee0, color-basic-600: #8f9bb3, color-basic-700: #2e3a59, color-basic-800: #222b45, color-basic-900: #192038, color-basic-1000: #151a30, color-basic-1100: #101426, color-basic-transparent-100:rgba(143, 155, 179, 0.08), color-basic-transparent-200:rgba(143, 155, 179, 0.16), color-basic-transparent-300:rgba(143, 155, 179, 0.24), color-basic-transparent-400:rgba(143, 155, 179, 0.32), color-basic-transparent-500: rgba(143, 155, 179, 0.4), color-basic-transparent-600:rgba(143, 155, 179, 0.48), color-basic-control-transparent-100:rgba(255, 255, 255, 0.08), color-basic-control-transparent-200:rgba(255, 255, 255, 0.16), color-basic-control-transparent-300:rgba(255, 255, 255, 0.24), color-basic-control-transparent-400:rgba(255, 255, 255, 0.32), color-basic-control-transparent-500: rgba(255, 255, 255, 0.4), color-basic-control-transparent-600: rgba(255, 255, 255, 0.48), user-initials-text-color: color-basic-200, user-name-text-color: color-basic-200, user-title-text-color: color-basic-200, select-min-width: 6rem, slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%), slide-out-shadow-color: 0 4px 14px 0 #CA005D, slide-out-shadow-color-rtl: 0 4px 14px 0 #CA005D,


scrollbar-width: 10px,
  scrollbar-color: #767ADF,
//   scrollbar-color: #EEAF30,
  scrollbar-background-color: none,
), compartamos, default);

// <- theme name and a parent theme
// $nb-themes: nb-register-theme((
//   color-primary-100: #faf7ff, // <- primary violet shades
//   color-primary-200: #ece3ff,
//   color-primary-300: #d5bfff,
//   color-primary-400: #00AE9E,
//   color-primary-500: #F58025,
//   color-primary-600: #7b51db,
//   color-primary-700: #5a37b8,
//   color-primary-800: #3e2494,
//   color-primary-900: #29157a,
//   color-basic-100: white,
//   color-basic-200: #eefafc,
//   color-basic-300: #e8f5fa,
//   color-basic-400: #d6e6f2,
//   color-basic-500: #c7dbeb,
//   color-basic-600: #a9c0db,
//   color-basic-700: #5E6A71,
//   color-basic-800: #31373B,
//   color-basic-900: #31373B,
//   color-basic-1000: #244555,
//   color-basic-1100: #121a2b,
//   background-basic-color-1: color-basic-700,
//   background-basic-color-2: color-basic-800,
//   background-basic-color-3: color-basic-900,
//   background-basic-color-4: color-basic-100,
//   border-basic-color-1: color-basic-700,
//   border-basic-color-2: color-basic-800,
//   border-basic-color-3: color-basic-900,
//   border-basic-color-4: color-basic-1000,
//   border-basic-color-5: color-basic-1100,
//   scrollbar-color: #B1004C,
//   scrollbar-background-color: none,
//   shadow: 0 0.5rem 1rem 0 rgba(25, 30, 51, 0.24),
//   text-disabled-color: color-basic-600,
// ), yastas dark); // <- theme name and a parent theme
// $nb-themes: nb-register-theme((
//   color-primary-100: #FEEBE0,
//   color-primary-200: #FED2C2,
//   color-primary-300: #FEB3A3,
//   color-primary-400: #FD968C,
//   color-primary-500: #FD6767,
//   color-primary-600: #D94B57,
//   color-primary-700: #B6334A,
//   color-primary-800: #92203E,
//   color-primary-900: #791336,
//   color-primary-transparent-100: rgba(253, 103, 103, 0.08),
//   color-primary-transparent-200: rgba(253, 103, 103, 0.16),
//   color-primary-transparent-300: rgba(253, 103, 103, 0.24),
//   color-primary-transparent-400: rgba(253, 103, 103, 0.32),
//   color-primary-transparent-500: rgba(253, 103, 103, 0.4),
//   color-primary-transparent-600: rgba(253, 103, 103, 0.48),
// ), compartamos, dark); // <- theme name and a parent theme
// $nb-themes: nb-register-theme((
//   color-primary-100: #FEEBE0,
//   color-primary-200: #FED2C2,
//   color-primary-300: #FEB3A3,
//   color-primary-400: #FD968C,
//   color-primary-500: #FD6767,
//   color-primary-600: #D94B57,
//   color-primary-700: #B6334A,
//   color-primary-800: #92203E,
//   color-primary-900: #791336,
//   color-primary-transparent-100: rgba(253, 103, 103, 0.08),
//   color-primary-transparent-200: rgba(253, 103, 103, 0.16),
//   color-primary-transparent-300: rgba(253, 103, 103, 0.24),
//   color-primary-transparent-400: rgba(253, 103, 103, 0.32),
//   color-primary-transparent-500: rgba(253, 103, 103, 0.4),
//   color-primary-transparent-600: rgba(253, 103, 103, 0.48),
// ), intermex, dark); // <- theme name and a parent theme
