// themes - our custom or/and out of the box themes
@use 'themes' as *;

// framework component styles
@use 'node_modules/@nebular/theme/styles/globals' as *;
//@import 'node_modules/@nebular/auth/styles/all';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';

@import 'node_modules/bootstrap/scss/_variables-dark.scss';
@import 'node_modules/bootstrap/scss/_maps.scss';
@import 'node_modules/bootstrap/scss/_mixins.scss';
@import 'node_modules/bootstrap/scss/_grid.scss';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  //@include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

.draw{
  border:1px solid red!important;
}

.validatorComponent{
  transition: all .3s linear;
}

.disabled-force{
  pointer-events: none!important;
  opacity: .6!important;
  filter:grayscale(.5);
}
.hidden{
  display: none!important;
}

.hidden-simple{
  pointer-events: none!important;
  opacity: 0!important;
  z-index: -1!important;
  position:absolute!important;
}

.hidden{
  display:none !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h6{
  font-size: 1.125rem!important;
  font-family: Roboto!important;
  font-weight: 700!important;
  line-height: 1.5rem!important;
}
h5{
  font-size: 1.375rem!important;
  font-family: Roboto!important;
  font-weight: 700!important;
  line-height: 2rem!important;
}
h4{
  font-size: 1.625rem!important;
  font-family: Roboto!important;
  font-weight: 700!important;
  line-height: 2rem!important;
}

